import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

interface SendIconProps extends SVGProps<SVGSVGElement> {
    disabled?: boolean
}

const SendIcon = ({ disabled, ...rest }: SendIconProps) => {
    const { palette } = useTheme()

    return (
        <svg width={22} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='-2 -1 28 28' {...rest}>
            <path
                d='M21.404.24c.433.301.661.821.58 1.341l-2.75 17.871a1.378 1.378 0 0 1-1.89 1.057l-5.14-2.135-2.944 3.183a1.375 1.375 0 0 1-2.384-.936v-3.592c0-.171.064-.335.18-.46l7.202-7.857a.682.682 0 0 0-.017-.945.687.687 0 0 0-.946-.03L4.555 15.5.761 13.601a1.369 1.369 0 0 1-.76-1.19c-.013-.507.253-.98.691-1.233L19.942.181a1.38 1.38 0 0 1 1.462.06Z'
                fill={disabled ? palette.text.disabled : palette.primary.contrastText}
            />
        </svg>
    )
}

export default SendIcon
