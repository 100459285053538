import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

interface FileIconProps extends SVGProps<SVGSVGElement> {
    inverted?: boolean
}

const FileIcon = ({ color, width = 24, height = 24, inverted = false, ...rest }: FileIconProps) => {
    const { palette } = useTheme()

    return inverted ? (
        <svg width={width} height={height} viewBox='-4 -1 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
            <path
                d='M13.9128 7.71429H8.3157C7.80675 7.71429 7.31864 7.5111 6.95876 7.14942C6.59887 6.78774 6.39669 6.2972 6.39669 5.78571V0.160714C6.39669 0.11809 6.37985 0.0772119 6.34986 0.0470722C6.31987 0.0169324 6.27919 0 6.23678 0H2.55868C1.88007 0 1.22926 0.270918 0.749419 0.753154C0.269574 1.23539 0 1.88944 0 2.57143V15.4286C0 16.1106 0.269574 16.7646 0.749419 17.2468C1.22926 17.7291 1.88007 18 2.55868 18H11.514C12.1927 18 12.8435 17.7291 13.3233 17.2468C13.8032 16.7646 14.0727 16.1106 14.0727 15.4286V7.875C14.0727 7.83238 14.0559 7.7915 14.0259 7.76136C13.9959 7.73122 13.9552 7.71429 13.9128 7.71429ZM10.2347 14.1429H3.83802C3.66837 14.1429 3.50566 14.0751 3.3857 13.9546C3.26574 13.834 3.19835 13.6705 3.19835 13.5C3.19835 13.3295 3.26574 13.166 3.3857 13.0454C3.50566 12.9249 3.66837 12.8571 3.83802 12.8571H10.2347C10.4044 12.8571 10.5671 12.9249 10.687 13.0454C10.807 13.166 10.8744 13.3295 10.8744 13.5C10.8744 13.6705 10.807 13.834 10.687 13.9546C10.5671 14.0751 10.4044 14.1429 10.2347 14.1429ZM10.2347 10.9286H3.83802C3.66837 10.9286 3.50566 10.8608 3.3857 10.7403C3.26574 10.6197 3.19835 10.4562 3.19835 10.2857C3.19835 10.1152 3.26574 9.9517 3.3857 9.83115C3.50566 9.71059 3.66837 9.64286 3.83802 9.64286H10.2347C10.4044 9.64286 10.5671 9.71059 10.687 9.83115C10.807 9.9517 10.8744 10.1152 10.8744 10.2857C10.8744 10.4562 10.807 10.6197 10.687 10.7403C10.5671 10.8608 10.4044 10.9286 10.2347 10.9286Z'
                fill={color || palette.text.primary}
            />
            <path
                d='M13.721 6.40199L7.67075 0.351739C7.65898 0.340043 7.64402 0.332086 7.62774 0.328868C7.61147 0.325651 7.5946 0.327316 7.57927 0.333656C7.56394 0.339995 7.55083 0.350725 7.54158 0.364498C7.53232 0.37827 7.52735 0.394469 7.52728 0.411059V5.87231C7.52728 6.05084 7.5982 6.22206 7.72444 6.34829C7.85068 6.47453 8.02189 6.54545 8.20042 6.54545H13.6617C13.6783 6.54538 13.6945 6.54041 13.7082 6.53116C13.722 6.52191 13.7327 6.50879 13.7391 6.49346C13.7454 6.47813 13.7471 6.46127 13.7439 6.44499C13.7406 6.42872 13.7327 6.41376 13.721 6.40199Z'
                fill={color || palette.text.primary}
            />
        </svg>
    ) : (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 30 30' fill='none' {...rest}>
            <path
                stroke={color || palette.text.primary}
                strokeLinejoin='round'
                strokeWidth={1.563}
                d='M22.75 12.54v10.983a2.75 2.75 0 0 1-.769 1.915 2.585 2.585 0 0 1-1.856.793H7.875a2.585 2.585 0 0 1-1.856-.793 2.75 2.75 0 0 1-.769-1.915V5.477c0-.718.277-1.407.769-1.915a2.585 2.585 0 0 1 1.856-.792h5.4c.464 0 .91.19 1.237.528l7.726 7.966c.328.339.512.798.512 1.276Z'
            />
            <path
                stroke={color || palette.text.primary}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.563}
                d='M14 3.22v6.768c0 .479.184.938.513 1.276.328.339.773.529 1.237.529h6.563M9.624 16.305h8.75m-8.75 4.511h8.75'
            />
        </svg>
    )
}

export default FileIcon
