import { styled, useTheme } from '@mui/material'
import AddIcon from './AddIcon'
import { SVGProps } from 'react'

const CrossIcon = ({ width = 30, height = 30, strokeWidth = 1.25, color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return <AddIcon width={width} height={height} strokeWidth={strokeWidth} color={color || palette.text.primary} {...rest} />
}

const RotatedIcon = styled(CrossIcon)({
    transform: 'rotate(45deg)',
})

export default RotatedIcon
