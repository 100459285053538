import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

const EditIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={17} height={17} stroke={color ? color : palette.text.primary} {...rest}>
            <path
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.4}
                d='M12.75 7.438v6.109a1.329 1.329 0 0 1-1.328 1.328H3.453a1.328 1.328 0 0 1-1.328-1.328V5.578A1.328 1.328 0 0 1 3.453 4.25h5.561'
            />
            <path
                fill={color ? color : palette.text.primary}
                strokeWidth={0.229}
                d='M13.179 2.907a.413.413 0 0 1 .583 0l.33.333a.413.413 0 0 1 0 .581L8.11 9.816l5.07-6.909Zm0 0L7.185 8.891l5.994-5.984ZM6.803 9.891l.277-.823a.413.413 0 0 1 .105-.177l-.382 1Zm0 0-.001.004m.001-.004-.001.004m0 0a.244.244 0 0 0 .061.242l-.061-.242Zm.303.304a.243.243 0 0 1-.13.002l.13-.002Zm0 0 .004-.002m-.004.002.004-.002m0 0 .823-.276m-.823.276.823-.276m0 0a.413.413 0 0 0 .177-.105l-.177.105Zm6.077-7.844a.38.38 0 0 0 0 .538l.376.375a.38.38 0 0 0 .539 0l.4-.398a.654.654 0 0 0 .032-.9.647.647 0 0 0-.936-.023l-.41.408Z'
            />
        </svg>
    )
}
export default EditIcon
