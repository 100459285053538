import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

const UndoIcon = (props: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' viewBox='-0.5 0 25 25' {...props}>
            <path stroke={palette.text.primary} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M10 21.42h5a7 7 0 1 0 0-14H2' />
            <path stroke={palette.text.primary} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='m6 11.42-4-4 4-4' />
        </svg>
    )
}

export default UndoIcon
