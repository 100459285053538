import styled from 'styled-components'
import ChatLayout from './ChatLayout'
import { useChatContext } from '../context/ChatContext'
import ReportLayout from './report/ReportLayout'
import { mediaQueries } from './common/styles'
import SkimmerLayout from './doc-skimmer/container/SkimmerLayout'

const Container = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 16px 0 0 0;
    border-radius: 16px 0 0 0;
    background-color: ${props => props.theme.colors.background};

    @media ${mediaQueries.mobile} {
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
`

interface MainAreaProps {
    className?: string
}

const MainArea = ({ className }: MainAreaProps) => {
    const { currentChat } = useChatContext()

    const getLayout = () => {
        switch (currentChat?.bot.toolType) {
            case 'report_generator':
                // Type inference can't automatically propagate the narrowing from the switch statement to the parent object
                // So we reconstruct the object with the narrowed bot type to avoid type assertions
                return <ReportLayout currentChat={{ ...currentChat, bot: currentChat.bot }} />
            case 'document_skimmer':
                return <SkimmerLayout currentChat={{ ...currentChat, bot: currentChat.bot }} />
            default:
                // Visually we want to default to the empty chat layout
                return <ChatLayout />
        }
    }

    return <Container className={className}>{getLayout()}</Container>
}

export default MainArea
