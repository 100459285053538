import { styled, Typography } from '@mui/material'
import { AiEngine } from '../types'
import { environmentVariables } from '../env'

const BotDetailsWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,

    table: {
        th: {
            textAlign: 'left',
        },
        td: {
            textAlign: 'left',
        },
    },
}))

interface BotOverviewInfoProps {
    aiEngines: AiEngine[]
}

const BotOverviewInfo = ({ aiEngines }: BotOverviewInfoProps) => {
    return (
        <BotDetailsWrapper>
            <table>
                <thead>
                    <tr>
                        <th>
                            <Typography variant='h6'>Engine</Typography>
                        </th>
                        <th>
                            <Typography variant='h6'>Model</Typography>
                        </th>
                        <th>
                            <Typography variant='h6'>Region</Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {aiEngines.map((engine, index) => (
                        <tr key={index}>
                            <td>
                                <Typography variant='body2'>{engine.displayName}</Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>{engine.model}</Typography>
                            </td>
                            <td>
                                <Typography variant='body2'>{engine.region}</Typography>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Typography variant='body2'>
                This tool processes your data using AI models in the regions listed above. The data is stored in {environmentVariables.dataStorageLocation}.
            </Typography>
        </BotDetailsWrapper>
    )
}

export default BotOverviewInfo
