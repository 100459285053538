import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

const HelpIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' {...rest}>
            <circle cx={9} cy={9} r={8.438} stroke={color ?? palette.text.primary} strokeWidth={1.125} />
            <path
                fill={color ?? palette.text.primary}
                d='M8.323 10.86c0-.259.024-.508.073-.748a2.39 2.39 0 0 1 .253-.675 1.7 1.7 0 0 1 .338-.473c.124-.12.262-.238.416-.354.154-.12.324-.28.512-.478a1.31 1.31 0 0 0 .304-.501c.048-.169.073-.349.073-.54 0-.124-.013-.24-.04-.349a.665.665 0 0 0-.14-.281.835.835 0 0 0-.405-.293 1.535 1.535 0 0 0-.529-.09c-.154 0-.302.021-.444.062a.906.906 0 0 0-.355.18.799.799 0 0 0-.247.332 1.085 1.085 0 0 0-.073.433h-1.43a2.86 2.86 0 0 1 .305-1.04c.168-.323.39-.58.663-.77.214-.158.454-.277.72-.355.27-.079.54-.118.81-.118.413 0 .801.063 1.165.19.367.128.673.338.917.63.168.192.296.413.382.665.09.25.135.506.135.765 0 .386-.084.738-.253 1.057a3.88 3.88 0 0 1-.63.883c-.12.128-.234.24-.343.338-.109.097-.21.193-.304.287-.09.09-.165.185-.225.286a1.313 1.313 0 0 0-.185.45c-.02.12-.029.29-.029.507H8.323Zm.011 2.2v-1.492h1.423v1.491H8.334Z'
            />
        </svg>
    )
}

export default HelpIcon
