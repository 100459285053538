import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

const CheckIcon = ({ color, ...rest }: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
            <path d='M1 3.5L5 7.5L11.5 1' stroke={color ?? palette.text.primary} strokeWidth='2' />
        </svg>
    )
}

export default CheckIcon
