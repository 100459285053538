import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { downloadChatEndpoint } from '../../endpoints'
import { useGet } from '../../hooks/useGet'
import DOMPurify from 'dompurify'
import ExportIcon from '../../icons/ExportIcon'
import TransparentButton from './TransparentButton'
import { UUID } from '../../types'
import InfoTooltip from './tooltips/InfoTooltip'

const PrintTarget = styled.div`
    float: left;

    @media print {
        visibility: visible;
    }

    @media screen {
        visibility: hidden;
    }
`

interface DownloadChatProps {
    chatId: UUID
    chatTitle: string
}

const DownloadChat = ({ chatId, chatTitle }: DownloadChatProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [chatHtml, loading, getChatHtml] = useGet<string>(downloadChatEndpoint(chatId), { requestOnRender: false })

    useEffect(() => {
        if (ref.current && chatHtml) {
            ref.current.innerHTML = DOMPurify.sanitize(chatHtml, { USE_PROFILES: { html: true } })
            document.title = `Transcript of ${chatTitle} - ${document.title}`
            window.print()
        }
        // We only want to print if the HTML updates from a refresh being requested
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatHtml])

    useEffect(() => {
        const originalTitle = document.title
        const removePrintContent = () => {
            if (ref.current) {
                ref.current.innerHTML = ''
                document.title = originalTitle
            }
        }

        window.addEventListener('afterprint', removePrintContent)

        return () => window.removeEventListener('afterprint', removePrintContent)
    }, [])

    return (
        <InfoTooltip title='Print or save a copy of your chat'>
            <TransparentButton onClick={getChatHtml} disabled={loading} type='button' aria-label='download chat'>
                {createPortal(<PrintTarget ref={ref}></PrintTarget>, document.body)}
                <ExportIcon /> Export Chat
            </TransparentButton>
        </InfoTooltip>
    )
}

export default DownloadChat
