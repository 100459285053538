import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { handledErrorCodes, isIgnorableError, loginRequest, selectAccountLoginRequest } from './authConfig'
import Login from './components/Login'
import MainArea from './components/MainArea'
import SidebarWrapper from './components/SidebarWrapper'
import { WithChatContext } from './context/ChatContext'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { WithMessageContext } from './context/MessageContext'
import {
    accorDarkTheme as AccorDarkStyledTheme,
    accorLightTheme as AccorLightStyledTheme,
    charlesRussellSpeechlysDarkTheme as CRSDarkStyledTheme,
    charlesRussellSpeechlysLightTheme as CRSLightStyledTheme,
    charlesRussellSpeechlysUATDarkTheme as CRSDarkStyledThemeUAT,
    charlesRussellSpeechlysUATLightTheme as CRSLightStyledThemeUAT,
    springbokDarkTheme as springbokDarkStyledTheme,
    springbokLightTheme as springbokLightStyledTheme,
} from './components/common/styles'
import {
    accorDarkTheme as AccorDarkMuiTheme,
    accorLightTheme as AccorLightMuiTheme,
    charlesRussellSpeechlysDarkTheme as CRSDarkMuiTheme,
    charlesRussellSpeechlysLightTheme as CRSLightMuiTheme,
    charlesRussellSpeechlysUATDarkTheme as CRSDarkMuiThemeUAT,
    charlesRussellSpeechlysUATLightTheme as CRSLightMuiThemeUAT,
    globalStyles,
    springbokDarkTheme as springbokDarkMuiTheme,
    springbokLightTheme as springbokLightMuiTheme,
} from './components/common/Theme'
import { environmentVariables } from './env'
import { captureException, captureMessage, setContext, setUser } from '@sentry/react'
import { WithFileContext } from './context/FileContext'
import { UUID } from './types'
import { supportMessage } from './utils/userMessages'
import { useDarkModeContext } from './context/DarkModeContext'
import { WithUserContext } from './context/UserContext'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import GuidancePage from './components/GuidancePage'
import { WithBotContext } from './context/BotContext'
import { WithPromptContext } from './context/PromptContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb'
import { GlobalStyles, styled, Typography } from '@mui/material'
import { semverWithoutBuild } from './utils/formatting'

const Container = styled('div')(({ theme }) => ({
    width: '100vw',
    height: '100dvh',
    display: 'flex',
    position: 'fixed',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}))

const StyledMainArea = styled(MainArea)({
    flex: 80,
    // Force being constrained to parents width even if content can't be wrapped
    minWidth: 0,
})

const Version = styled(Typography)({
    position: 'absolute',
    bottom: '5px',
    right: '5px',
    cursor: 'default',
    opacity: 0.2,
    '&:hover': {
        opacity: 1,
    },
})

const getStyledTheme = (isDarkMode: boolean) => {
    switch (environmentVariables.company) {
        case 'Accor':
            return isDarkMode ? AccorDarkStyledTheme : AccorLightStyledTheme
        case 'CharlesRussellSpeechlys':
            return isDarkMode ? CRSDarkStyledTheme : CRSLightStyledTheme
        case 'CharlesRussellSpeechlysUAT':
            return isDarkMode ? CRSDarkStyledThemeUAT : CRSLightStyledThemeUAT
        default:
            return isDarkMode ? springbokDarkStyledTheme : springbokLightStyledTheme
    }
}

const getMuiTheme = (isDarkMode: boolean) => {
    switch (environmentVariables.company) {
        case 'Accor':
            return isDarkMode ? AccorDarkMuiTheme : AccorLightMuiTheme
        case 'CharlesRussellSpeechlys':
            return isDarkMode ? CRSDarkMuiTheme : CRSLightMuiTheme
        case 'CharlesRussellSpeechlysUAT':
            return isDarkMode ? CRSDarkMuiThemeUAT : CRSLightMuiThemeUAT
        default:
            return isDarkMode ? springbokDarkMuiTheme : springbokLightMuiTheme
    }
}

const getScreenDetails = () => ({
    resolution: `${window.screen.width}x${window.screen.height}`,
    viewport: `${window.innerWidth}x${window.innerHeight}`,
})

const App = () => {
    const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest)
    const { accounts, instance, inProgress } = useMsal()
    const { isDarkMode } = useDarkModeContext()
    const [authenticated, setAuthenticated] = useState(false)
    const [showGuidancePage, setShowGuidancePage] = useState(
        environmentVariables.company === 'CharlesRussellSpeechlys' || environmentVariables.company === 'CharlesRussellSpeechlysUAT'
    )

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            // Do this outside the if statement as we don't want to handle or log interaction required errors
            inProgress === InteractionStatus.None && login(InteractionType.Popup, selectAccountLoginRequest)
        } else if (error && !isIgnorableError(error)) {
            switch (error.errorCode) {
                case handledErrorCodes.popup:
                    toast.warn('Login popup blocked. Please enable popups on this site.')
                    break
                case handledErrorCodes.empty:
                    toast.warn('The login popup was either blocked or empty. Please ensure popups are enabled on this site and try again.')
                    break
                case handledErrorCodes.client:
                    toast.warn('Please check that you are using an account that has permission to access the site.')
                    break
                case handledErrorCodes.inProgress:
                    toast.warn('You are already trying to log in, please check for a popup window.')
                    break
                default:
                    captureException(error)
                    toast.error(`Unable to log in. ${supportMessage}`)
                    break
            }
        }
    }, [error, inProgress, login])

    useEffect(() => {
        const validAccounts = accounts.filter(a => environmentVariables.allowedTenantIds.includes(a.tenantId as UUID))

        const setAccount = () => {
            const account = validAccounts[0]

            instance.setActiveAccount(account)
            setAuthenticated(true)
            setUser({
                email: account.username,
            })
            setContext('screen', getScreenDetails())
        }

        if (validAccounts.length === 1) {
            setAccount()
        } else if (validAccounts.length > 1) {
            toast.warn(
                'You have multiple accounts logged in. We will attempt to automatically select the relevant account. If you encounter issues please log out of all other accounts.'
            )
            captureMessage('User has multiple accounts')
            setAccount()
        } else if (accounts.length) {
            toast.error(
                'No account belonging to an authorised tenancy was found. Please check you logged in to the correct account and contact your administrator if you believe this is incorrect.'
            )
            captureMessage('User has no account with valid tenancy')
        } else {
            setAuthenticated(false)
            instance.setActiveAccount(null)
            setUser(null)
        }
    }, [accounts, instance])

    useEffect(() => {
        const handler = () => setContext('screen', getScreenDetails())

        let timer: NodeJS.Timeout | null
        const debouncedHandler = () => {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(handler, 5000)
        }

        window.addEventListener('resize', debouncedHandler)
        return () => window.removeEventListener('resize', debouncedHandler)
    }, [])

    return (
        <StyledThemeProvider theme={getStyledTheme(isDarkMode)}>
            <MuiThemeProvider theme={getMuiTheme(isDarkMode)}>
                <GlobalStyles styles={globalStyles} />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                    {authenticated ? (
                        <Container>
                            {showGuidancePage ? (
                                <GuidancePage onContinue={() => setShowGuidancePage(false)} />
                            ) : (
                                <DndProvider backend={HTML5Backend}>
                                    <WithUserContext>
                                        <WithChatContext>
                                            <WithBotContext>
                                                <WithFileContext>
                                                    <WithMessageContext>
                                                        <SidebarWrapper />
                                                        <WithPromptContext>
                                                            <StyledMainArea />
                                                        </WithPromptContext>
                                                    </WithMessageContext>
                                                </WithFileContext>
                                            </WithBotContext>
                                        </WithChatContext>
                                    </WithUserContext>
                                </DndProvider>
                            )}
                        </Container>
                    ) : (
                        <Login />
                    )}
                    <Version variant='caption' color='textSecondary'>
                        {semverWithoutBuild(environmentVariables.version)}
                    </Version>
                    <ToastContainer closeOnClick stacked theme={isDarkMode ? 'dark' : 'light'} />
                </LocalizationProvider>
            </MuiThemeProvider>
        </StyledThemeProvider>
    )
}

export default App
