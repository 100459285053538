import { SVGProps } from 'react'
import { useTheme } from '@mui/material'

const ExportIcon = ({ color, width = 24, height = 24, strokeWidth = 32, ...rest }: SVGProps<SVGSVGElement>) => {
    const { palette } = useTheme()

    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 512 512' {...rest}>
            <path
                d='M336 176h40a40 40 0 0140 40v208a40 40 0 01-40 40H136a40 40 0 01-40-40V216a40 40 0 0140-40h40'
                fill='none'
                stroke={color || palette.text.primary}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={strokeWidth}
            />
            <path
                fill='none'
                stroke={color || palette.text.primary}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={strokeWidth}
                d='M176 272l80 80 80-80M256 48v288'
            />
        </svg>
    )
}

export default ExportIcon
